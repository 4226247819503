body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}




.slider-example-focus {
  /* #549ef7 #ffa658
    The style below isn't necessary.
    It has been added for the sake of smoothness.
  
  transition: box-shadow 200ms ease-in-out;*/
  box-shadow: 0px 0px 10px 5px #6464647a;
  display: block;

  --default-handle-shadow: 0px 0px 5px rgba(0, 0, 0, 1);
  --divider-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
  --divider-width:2;
  --default-handle-width: 100px;
  --divider-color: #549ef7;
  --default-handle-color: #549ef7;
  stroke-width: 3px;
  ;
}

.slider-example-focus:focus {
  outline: none;
  box-shadow: 0px 0px 10px 5px #6464647a;
}

.checkerboard {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><rect x="0" y="0" width="10" height="10" fill="%23ccc"/><rect x="10" y="10" width="10" height="10" fill="%23ccc"/><rect x="10" y="0" width="10" height="10" fill="%23fff"/><rect x="0" y="10" width="10" height="10" fill="%23fff"/></svg>');
  background-size: 20px 20px;
}

.magnifying-glass {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><rect x="0" y="0" width="10" height="10" fill="%23ccc"/><rect x="10" y="10" width="10" height="10" fill="%23ccc"/><rect x="10" y="0" width="10" height="10" fill="%23fff"/><rect x="0" y="10" width="10" height="10" fill="%23fff"/></svg>');
  background-size: 20px 20px;
}